import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {ProgressBarService} from './services/progress-bar.service';
import {NavigationService} from './services/navigation.service';
import {RouterModule, Router} from '@angular/router';
import {Error404Page} from './pages/error404/error404.page';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AuthenticationService} from './services/authentication.service';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {RolesService} from './services/roles.service';
import {QxPageComponent} from './pages/core/qx-page.component';
import {PerfilModule} from './pages/perfil/perfil.module';
import { AdDirective } from './crud/nomenclador/directives/ad.directive';
import { DialogNomencladorComponenet } from './crud/nomenclador/dialog-nomenclador.component';
import { AuthModule } from './pages/auth/auth.module';
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import { DialogPerfil } from './pages/dialog-perfil/dialog-perfil.component';
import { DialogPerfilService } from './pages/dialog-perfil/dialog-perfil.service';
import { DialogAlertComponent } from './helpers/dialog-alert/dialog-alert.component';
import { DATE_FORMAT_VIEW } from './config/config';

export const MY_FORMATS = {
    parse: {
      dateInput: DATE_FORMAT_VIEW,
    },
    display: {
      dateInput: DATE_FORMAT_VIEW,
      monthYearLabel: 'MMM YYYY',
      dateA11yLabel: DATE_FORMAT_VIEW,
      monthYearA11yLabel: 'MMMM YYYY',
    },
  };

  export class PaginatorI18n extends MatPaginatorIntl {
    itemsPerPageLabel = "Elementos por Página";
    firstPageLabel = "Primera Página";
    lastPageLabel = "Última Página";
    nextPageLabel = "Próxima Página";
    previousPageLabel = "Página Anterior";
    getRangeLabel = (page: number, pageSize: number, length: number): string => {
        return `Página ${page + 1} de ${length} Elementos encontrados`;
    }
  }

@NgModule({
    imports: [
        CommonModule,
        AuthModule,
        MaterialModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        PerfilModule,
        HttpClientModule,
        ComponentsModule,        
         //Fury
    FurySharedModule,      
    HighlightModule,
    FuryCardModule,
    BreadcrumbsModule,
    MaterialModule,
    PageModule,
    ListModule,
    MatSnackBarModule,
    ],
    declarations: [        
        DialogPerfil,
        QxPageComponent,
        Error404Page,
        AdDirective,
        DialogNomencladorComponenet,
        DialogAlertComponent,
        LazyDownloadDirective,
    ],
    exports: [
        MaterialModule,
        ComponentsModule,
        DialogPerfil,
        QxPageComponent,   
        LazyDownloadDirective,   
    ],
    entryComponents: [        
        DialogPerfil,       
        DialogNomencladorComponenet,
        DialogAlertComponent,
        LazyDownloadDirective,
    ],
    providers: [,
        AppConfigService,
        ProgressBarService,
        NavigationService,
        AuthenticationService,
        DialogPerfilService,   
        RolesService,        
        {provide: MAT_DATE_LOCALE, useValue: 'es'},
        {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
        {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
        {provide: MatPaginatorIntl, useValue: new PaginatorI18n()}
    ]
})
export class GlobalModule {
    constructor(snackBarService: SnackBarService) {
        AppUtils.snackBar = snackBarService;
    }
}

//Congig Moment i18n
import * as moment from 'moment';
import { ComponentsModule } from './components/components.module';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MaterialModule } from '../../@fury/shared/material-components.module';
import { FurySharedModule } from '../../@fury/fury-shared.module';
import { HighlightModule } from '../../@fury/shared/highlightjs/highlight.module';
import { FuryCardModule } from '../../@fury/shared/card/card.module';
import { BreadcrumbsModule } from '../../@fury/shared/breadcrumbs/breadcrumbs.module';
import { PageModule } from '../../@fury/shared/page/page.module';
import { ListModule } from '../../@fury/shared/list/list.module';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { AppConfigService } from './services/app-config.service';
import { OAuthNoopResourceServerErrorHandler } from './interceptors/resource-server-error-handler';

import { LazyDownloadDirective } from './lazy-download/lazy-download.directive';
import { SnackBarService } from './helpers/snack-bar.service';
import { AppUtils } from './helpers/app.utils';

moment.locale('es', {
    months : "Enero_Febrero_Marzo_Abril_Mayo_Junio_Jullio_Agosto_Septiembre_Octubre_Noviembre_Diciembre".split("_"),
    monthsShort : "Ene_Feb_Mar_Abr_May_Jun_Jul_Ago_Sept_Oct_Nov_Dec".split("_"),
    weekdays : "Domingo_Lunes_Martes_Mi\u00e9rcoles_Jueves_Viernes_S\u00e1bado".split("_"),
    weekdaysShort : "Dom_Lun_Mar_Mi\u00e9r_Jue_Vie_S\u00e1b".split("_"),
    weekdaysMin : "D_L_M_M_J_V_S".split("_"),
    longDateFormat : {
        LT : "HH:mm",
        LTS : "HH:mm:ss",
        L : "DD/MM/YYYY",
        LL : "D MMMM YYYY",
        LLL : "D MMMM YYYY LT",
        LLLL : "dddd D MMMM YYYY LT"
    },
    calendar : {
        sameDay: "[Aujourd'hui à] LT",
        nextDay: '[Demain à] LT',
        nextWeek: 'dddd [à] LT',
        lastDay: '[Hier à] LT',
        lastWeek: 'dddd [dernier à] LT',
        sameElse: 'L'
    },
    relativeTime : {
        future : "dans %s",
        past : "il y a %s",
        s : "quelques secondes",
        m : "une minute",
        mm : "%d minutes",
        h : "une heure",
        hh : "%d heures",
        d : "un jour",
        dd : "%d jours",
        M : "un mois",
        MM : "%d mois",
        y : "une année",
        yy : "%d années"
    },
    ordinalParse : /\d{1,2}(er|ème)/,
    ordinal : function (number) {
        return number + (number === 1 ? 'er' : 'ème');
    },
    meridiemParse: /PD|MD/,
    isPM: function (input) {
        return input.charAt(0) === 'M';
    },
    // in case the meridiem units are not separated around 12, then implement
    // this function (look at locale/id.js for an example)
    // meridiemHour : function (hour, meridiem) {
    //     return /* 0-23 hour, given meridiem token and hour 1-12 */
    // },
    meridiem : function (hours, minutes, isLower) {
        return hours < 12 ? 'PD' : 'MD';
    },
    week : {
        dow : 1, // Monday is the first day of the week.
        doy : 4  // The week that contains Jan 4th is the first week of the year.
    }
});

moment.locale('es');