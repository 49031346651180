import {Component, EventEmitter, Input, Output, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
    selector: 'qx-pagination',
    templateUrl: './qx-pagination.component.html',
    styleUrls: ['./qx-pagination.component.scss']
})
export class QxPaginationComponent implements OnInit {
    @Input() totalitems: number;
    @Input() currentpage: number;
    @Input() itemperpage: number;
    @Input() totalpage: number;
    @Input() currentitems: number;
    @Output() currentpageChange: EventEmitter<any>;
    @Output() itemperpageChange: EventEmitter<any>;
    @Output() search: EventEmitter<any>;
    form: FormGroup;

    constructor(public fb: FormBuilder) {
        this.totalitems = 0;
        this.currentpage = 1;
        this.currentitems = 0;
        this.itemperpage = 20;
        this.totalpage = 0;
        this.currentpageChange = new EventEmitter();
        this.itemperpageChange = new EventEmitter();
        this.search = new EventEmitter();
    }

    ngOnInit() {
        this.form = this.fb.group({
            itemperpage: [this.itemperpage, [Validators.required]]
        });
        
        this.form.get('itemperpage').valueChanges.subscribe(res => {
            this.itemperpage = res;
            this.currentpage = 0;
            this.pageChanged();
        });
    }

    onSearch(e) {
        this.search.emit(e);
    }

    pageChanged() {
        this.currentpageChange.emit(this.currentpage);
        this.itemperpageChange.emit(this.itemperpage);
        this.search.emit(true);
    }

    nextPage() {
        if (this.currentpage + 1 < this.totalpage) {
            this.currentpage++;
            this.pageChanged();
        }
    }

    prevPage() {
        if (this.currentpage > 0) {
            this.currentpage--;
            this.pageChanged();
        }
    }

    firstPage() {
        if (this.currentpage > 0) {
            this.currentpage = 0;
            this.pageChanged();
        }
    }

    lastPage() {
        if (this.currentpage + 1 < this.totalpage) {
            this.currentpage = this.totalpage - 1;
            this.pageChanged();
        }
    }
}
