import {AppUtils} from './app.utils';

declare const alertify: any;

export class Notifications {
    constructor() {
    }

    verification(res) {
        switch (res.status) {
            case 201:
                alertify.success('<i class="fa fa-check-circle"></i> Datos guardados correctamente.');
                break;
            case 204:
                alertify.success('<i class="fa fa-warning"></i> Eliminado correctamente.');
                break;
            case 200:
                alertify.success('<i class="fa fa-check-circle"></i> Sus datos han sido guardados satisfactoriamente.');
                break;
            case 422:
                alertify.error('<i class="fa fa-warning"></i> ' + res.json().errors[0].detail + '.');
                break;
            case 401:
                let msj = res.error.error_description;
                alertify.error('<i class="fa fa-warning"></i> ' + msj);
                AppUtils.logout();
                break;
            case 0:
            case 504:
                alertify.error('<i class="fa fa-warning"></i> No existe conexión con el servidor.');
                break;
            case 500:
                alertify.error('<i class="fa fa-warning"></i> Error en el servidor.');
                break;
            case 400:
                alertify.error('<i class="fa fa-warning"></i> Error en el envio de datos.');
                break;
            case 404:
                alertify.error('<i class="fa fa-warning"></i> Dirección no encontrada.');
                break;
        }
    }

    success(message) {
        AppUtils.success(message);
    }

    warn(message) {
        AppUtils.warn(message);
    }

    info(message) {
        AppUtils.info(message);
    }

    error(message) {
        AppUtils.error(message);
    }

    showMessage(type = 'success', mess) {
        switch (type) {
            case 'success':
                this.success(mess);
                break;
            case 'error':
                this.error(mess);
                break;
        }
    }
}


