<!-- sidebar menu -->
<div id="sidebar-menu" class="main_menu">
    <div class="menu_section">
        <ul id="metismenu" class="nav side-menu">            
            <li *ngFor="let m of navigation.modules" class="mm-active">
                <div [class]="!isModuleActive(m)?'hide':'menu-header'">
                    <label class="menu-title">{{ m.title || m.name }}</label>
                </div>
                <ul [class]="!isModuleActive(m)?'hide':'visible'">
                    <li *ngFor="let link of m.menu" routerLinkActive="active" [ngClass]="{'mm-active':link.active}">
                        <a mat-flat-button routerLink="{{link.url}}" [ngClass]="{'active':link.active}"
                           *ngIf="!link.childrens">
                            {{ link.name }}
                        </a>
                        
                        <a href="#" *ngIf="link.childrens" class="menu-group has-arrow" aria-expanded="false">
                            {{ link.name }}
                        </a>
                        <ul *ngIf="link.childrens">
                            <li *ngFor="let child of link.childrens">
                                <a mat-flat-button routerLink="{{child.url}}" [ngClass]="{'active':child.active}">
                                    {{ child.name }}
                                </a>
                            </li>
                        </ul>                        
                    </li>
                </ul>
            </li>            
        </ul>        
    </div>
</div>

<span>{{ initMetisMenu() }}</span>