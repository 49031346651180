import {Injectable} from '@angular/core';
import {AppUtils} from '../helpers/app.utils';
import {config} from '../config/config';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { NavigationService } from './navigation.service';
import { catchError, tap } from 'rxjs/operators';


@Injectable()
export class AuthenticationService {
    public synced: boolean;

    constructor(private http: HttpClient,private navigation:NavigationService) {
    }

  //  login(username: string, password: string) {
   //     this.logout();

   //     let headers = new HttpHeaders();
   //     headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
   //     const body = `username=${username}&password=${password}`;        

   //     const authUrl = environment.production ? 'webapp/login' : 'api/login';
   //     return this.http.post(authUrl, body, {headers: headers});

        // return this.http.get(config.urls.base_auth_url, {headers: headers});
  //  }

    login(username: string, password: string) {
        this.synced = false;
        const oauthCredentials: any = AppUtils.getOauthCredential();        
      
        let headers = new HttpHeaders();
        headers = headers.set('Authorization', 'Basic ' + btoa(oauthCredentials.key + ':' + oauthCredentials.secret));      
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
        const body = new HttpParams()
         .set('username',username)
         .set('password',password)
         .set('grant_type','password');
       // const body = `grant_type=password&username=${username}&password=${password}`;            ''        
        return this.http.post<any>('/oauth/token', body, {headers: headers}).pipe(
            tap(res => this.proccessToken(res))
        );
        // return this.http.get(config.urls.base_auth_url, {headers: headers});
    }

    private proccessToken(res: any): void {
        localStorage.accessTken = res.token_type + ' ' + res.access_token;
    }

    loadSession() {
        AppUtils.sessionStatusSubject.next('live');
        return this.http.get('api/user/me');
    }

    sync(data: any = {}) {
        this.synced = true;
        const user = AppUtils.load('currentUser');
    }

    logout() {
        this.navigation.cleanMenuItem();
        AppUtils.logout();
        this.synced = false;
    }

    public appInfo(): Observable<any>{
        return this.http.get("api/app-info");
       }

       public logoutOauth(): Observable<any>{
        return this.http.post("api/logout", {});
       }

       buscarGrupos(): Observable<any> {
        return this.http.get('api/nomenclador/grupo');
    }

}
