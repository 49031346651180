<header>
    <mat-toolbar color="primary" class="mat-elevation-z5">
        <mat-toolbar-row>
            <div class="col-sm-12 row ajustar">
                <div class="col-sm-3 row ajustar">
                    <button mat-icon-button (click)="openSideMenu()">
                        <mat-icon >more_vert</mat-icon>
                    </button>
                      <div style="margin-top: 6px;">
                            <span>{{proyecName}}</span>
                            <span class="test" *ngIf="appInfo.mode == 'test'">PRUEBA</span>
                      </div>                    
                </div>
                <div class="col-sm-6">
                    <div class="modules-nav flex-full">
                        <mat-tab-group class="demo-tab-group" (selectedTabChange)="selectModule(true)"
                            [(selectedIndex)]="navigation.activeTab" color="accent">
                            <ng-container *ngFor="let item of modules">
                                <mat-tab>
                                    <ng-template mat-tab-label>
                                        <span style="color: #FFFFFF">{{item.name}}</span>
                                    </ng-template>
                                </mat-tab>
                            </ng-container>

                        </mat-tab-group>
                    </div>
                </div>
              
                <div class="col-sm-1 ajustar" style="text-align: right;">
                    <button mat-flat-button [matMenuTriggerFor]="menu" color="primary" style="margin-top: 6px;">
                        <span *ngIf="currentUser">{{currentUser?.username}}</span>
                    </button>

                    <mat-menu #menu="matMenu">
                        <button mat-menu-item (click)="miPerfil()">
                            Cambiar mi Contraseña
                        </button>
                        <button mat-menu-item (click)="logout()">
                            Cerrar Sesión
                        </button>
                    </mat-menu>
                </div>
            </div>
        </mat-toolbar-row>
    </mat-toolbar>
</header>