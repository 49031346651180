import {User} from '../models/user';
import {BehaviorSubject, Observable} from 'rxjs';
import * as moment from "moment";
import { EventEmitter } from '@angular/core';
import { SnackBarService } from './snack-bar.service';
import { APP_CONFIG } from '../../app.config';

declare const alertify: any;

export class AppUtils {
    static snackBar: SnackBarService;
   
    public static sessionStatusSubject: BehaviorSubject<string> = new BehaviorSubject('void');
    public static sessionTime: number = 30 * 60 * 1000; // 30 minutos
    public static intervalExp: number = 30;
    public static filtersCache: any = {};
    public static tag: any;

    public static logout() {    
        AppUtils.del('currentUser');    
        AppUtils.del('access_token');   
        const config = APP_CONFIG[APP_CONFIG.active_profile];
        const url_logout_hosted_ui =config.url_logout_hosted_ui;
        window.location.href=url_logout_hosted_ui;      
    }

    public static initHostedUi(){
        const config = APP_CONFIG[APP_CONFIG.active_profile];
        const url_hosted_ui =config.url_hosted_ui;
        window.location.href=url_hosted_ui;     
    }
    
    /* public static logout() {
     
        let apiUrl =  AppUtils.urlApi();
        AppUtils.clearLocalStorage();
        if(!isNullOrUndefined(apiUrl)){
        AppUtils.save('apiUrl',apiUrl);
       }
        AppUtils.sessionStatusSubject.next('logout');
    }
 */
    public static clearLocalStorage() {
        localStorage.clear();

        /*const appKeys: Array<any> = AppUtils.load('appKeys') || [];
        if (appKeys.length) {
            appKeys.map(key => {
                AppUtils.del(key);
            });
        }
        AppUtils.del('appKeys');*/
    }

    public static save(key, val) {
        localStorage.setItem(btoa(key), btoa(JSON.stringify(val)));
        let appKeys: Array<any> = AppUtils.load('appKeys') || [];
        if (AppUtils.isNullOrUndefined(appKeys)) {
            appKeys = [];
        }
        appKeys.push(key);
        localStorage.setItem(btoa('appKeys'), btoa(JSON.stringify(appKeys)));

    }

    public static load(key) {
        try {
            return JSON.parse(atob(localStorage.getItem(btoa(key))));
        } catch (e) {
            return null;
        }
    }

    public static del(key) {
        localStorage.removeItem(btoa(key));
    }

    public static currentUser(): User {
        return AppUtils.load('currentUser');
    }

    public static grupoId(): User {
        return AppUtils.load('grupo');
    }

    public static urlApi(): string {
        return AppUtils.load('apiUrl');
    }

    public static updateLastRequest() {
        const actualTime = moment().unix();
        this.save('last-request', actualTime)
    };

    public static initTimeTokend(exp) {
        const actualTime = moment().unix();
        this.save('exp-time', actualTime + exp)
    };

    public static setUserTokend(data) {
        let user = AppUtils.currentUser();        
      //  user.refreshToken = data.refreshToken;
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        AppUtils.save('currentUser', user);
       // AppUtils.initTimeTokend(data.exp);
        AppUtils.sessionStatusSubject.next('live');
    }

    public static verifyAnyPerm(roles: any[]): boolean {
        let verify: boolean = false;
        roles.forEach(rol => {
            if (AppUtils.verifyPerm(rol)) {
                verify = true;
            }
        });
        return verify;
    }

    public static verifyPerm(rol): boolean {
        const user = AppUtils.currentUser(); 
        if(!AppUtils.isNullOrUndefined(user)){
        return user.authorities.filter(a => a.authority == rol).length > 0;
       }
       return false;
    }

    public static clone(data: any): any {
        const str = JSON.stringify(data);
        return JSON.parse(str);
    }

    public static back() {
        window.history.back();
    }

    public static success(message: string) {
        AppUtils.snackBar.success(message);
    }

    public static warn(message: string) {
        AppUtils.snackBar.warning(message);
    }

    public static info(message: string) {
        AppUtils.snackBar.info(message);
    }

    public static error(message: string) {
        AppUtils.snackBar.error(message);
    }

    public static confirm(message: string, callback: CallbackFn) {
        alertify.dialog('confirm')
            .setting({
                'title': 'Atención!',
                'labels': {ok: 'Si', cancel: 'No'},
                'message': message,
                'onok': callback,
            }).show();
    }

    public static confirm2(message: string, callbackok: CallbackFn, callbackcancel: CallbackFn) {
        alertify.dialog('confirm')
            .setting({
                'title': 'Confirmación!',
                'labels': {ok: 'Si', cancel: 'No'},
                'message': message,
                'onok': callbackok,
                'oncancel': callbackcancel,
            }).show();
    }



    public static prompt(message: string, type: string = 'text'): Observable<any> {
        const event = new EventEmitter();

        alertify.prompt('confirm')
            .setting({
                'title': 'Atención!',
                'labels': {ok: 'Si', cancel: 'No'},
                'message': message,                
                'type': type,
                'onok': (evt, value) => event.emit(value),
            }).show();
        
        return event;
    }

    public static getOauthCredential():any{
        return  {           
            key: 'webapp',
            secret: 'rapid-ask-wenapp'
          }
    }

    public static releaseWeb() {
      return "4-08-2020.1";
    }


    public static selectFirstMenuShow(menus) {  
        var primermenu = null;
        for (let index = 0; index < menus.length; index++) {      
            const element = menus[index];
            if(element.routeOrFunction){     
                primermenu = element;
                return primermenu;
            }
            if(element.subItems){
                for (let index = 0; index < element.subItems.length; index++) {  
                    const subElement = element.subItems[index];
                    if(subElement.routeOrFunction){     
                        primermenu = subElement;
                        return primermenu;
                    }                  
                }
             
            }
        }
    
    }


    //////////////////////////////////////////////////////

    public static isArray(object: any): boolean{
        return Array.isArray(object);
      }
    
      public static isBoolean(object: any): boolean{
       return typeof object === 'boolean';
      }
    
      public static isFunction(object: any): boolean{
       return typeof object === 'function'
      }
    
      public static isNull(object: any): boolean{
       return object === null;
      }
    
      public static isNullOrUndefined(object: any): boolean{
          return object === null || object === undefined;
      }
    
      public static isUndefined(object: any): boolean{
           return object === undefined
       }
    
       public static isNumber(object: any): boolean{
        return typeof object === 'number';
      }
     
      public static isObject(object: any): boolean{
          return object !== null && typeof object === 'object'
      }
     
      public static isString(object: any): object is string{
          return typeof object === 'string';
      }
    
}


export interface CallbackFn {
    (input): void;
}