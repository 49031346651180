import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
    selector: 'banner-title',
    templateUrl: './banner-title.component.html',
    styleUrls: ['./banner-title.component.scss']
})
export class BannerTitleComponent {
    @Input() title: string;
    @Input() back: boolean=true;
    @Output() goBack: EventEmitter<any>;

    constructor() {
        this.goBack = new EventEmitter<any>();
    }

}
