
<div  style="padding-left: 0px;" mat-dialog-title class="col-sm-12">
        {{title}}
      <mat-icon  style="cursor:pointer; float: right;padding-left: 54px;padding-right: 10px;" (click)="dialogRef.close()">close</mat-icon> 
</div>

<mat-dialog-content>
        <div class="scrollable" >
        <ng-template ad-host></ng-template>        
        </div>
</mat-dialog-content> 

<mat-dialog-actions fxLayout="row" fxLayoutAlign="end center" style="padding-bottom: 20px;">
        <button type="button" mat-button (click)="delete()" *ngIf="id && ver">
            ELIMINAR
        </button>
        <button  type="button" mat-button style="color: #ffff;cursor: pointer;" [disabled]="!validForm" (click)="save()" *ngIf="ver">
            GUARDAR
        </button>
    </mat-dialog-actions>



