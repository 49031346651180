import { ModuleFuryConfig } from '@quiox/global/config/config';

const encuesta_cas = 'http://localhost:8010/auth/login';

export const supervisormenu  : ModuleFuryConfig = {     
    name: 'Supervisor',
    menu: [
        {
            name: 'Supervisor',
            position: 10,
            type: 'subheading',
            customClass: 'first-subheading',
            roles: [
                'ROLE_SUPERVISOR2'             
                
            ]
          },
          {
            name: 'Decl. En Proceso',
            routeOrFunction: '/supervisor',
            icon: 'group',
            position: 15,
            roles: [
                'ROLE_SUPERVISOR2'
            ]
          },
          {
            name: 'Decl. Terminadas',
            routeOrFunction: '/supervisor/terminado',
            icon: 'group',
            position: 20,
            roles: [
                'ROLE_GESTIONAR_USUARIO'
            ]
          },
          {
            name: 'Decl. No Iniciadas',
            routeOrFunction: '/supervisor/noiniciadas',
            icon: 'group',
            position: 25,
            roles: [
                'ROLE_SUPERVISOR2'
            ]
          },
          {
            name: 'Perfil de Usuario',
            routeOrFunction: '/supervisor/perfilUsuario',
            icon: 'group',
            position: 30,
            roles: [
                'ROLE_SUPERVISOR2'
            ]
          },
       
    ]
        
};
