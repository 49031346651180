import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {HeaderComponent} from './header/header.component';
import {MenuComponent} from './menu/menu.component';
import {QxPaginationComponent} from './qx-pagination/qx-pagination.component';
import {BannerTitleComponent} from './banner-title/banner-title.component';
import {ButtonActionComponent} from './button-action/button-action.component';
import { QxDetailsFieldComponent } from './qx-details/qx-details-field.component';
import { QxDetailsComponent } from './qx-details/qx-details.component';
import { QxTimeComponent } from './qx-time/qx-time.component';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { QxCleanControlComponent } from './qx-clean-control/qx-clean-control.component';
import { MaterialModule } from '../../../@fury/shared/material-components.module';
import { QxTableComponent } from './qx-table/qx-table.component';
import { QxFilterModule } from './qx-filter/qx-filter.module';
import { FurySharedModule } from '../../../@fury/fury-shared.module';
import { HighlightModule } from '../../../@fury/shared/highlightjs/highlight.module';
import { FuryCardModule } from '../../../@fury/shared/card/card.module';
import { BreadcrumbsModule } from '../../../@fury/shared/breadcrumbs/breadcrumbs.module';
import { ListModule } from '../../../@fury/shared/list/list.module';

@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        QxFilterModule,
        FurySharedModule,
           // Core
      HighlightModule,
    FuryCardModule,
    BreadcrumbsModule,    
    ListModule
    ],
    declarations: [
        HeaderComponent,
        MenuComponent,
        QxTableComponent,
        QxDetailsComponent,
        QxDetailsFieldComponent,
        QxPaginationComponent,        
        BannerTitleComponent,
        ButtonActionComponent,        
        QxTimeComponent,
        QxCleanControlComponent
    ],
    exports: [
        HeaderComponent,
        MenuComponent,    
        QxTableComponent,  
        QxDetailsComponent,
        QxDetailsFieldComponent,
        QxPaginationComponent,
        BannerTitleComponent,
        ButtonActionComponent,
        QxTimeComponent,
        QxCleanControlComponent,
    ]
})
export class ComponentsModule {
}