<div class="error-404">
    <div class="container">
        <div class="row">
            <div class="col-sm-4 offset-sm-4 text-center error-body">
                <img class="img-fluid content-center" src="assets/img/error-404-v3.png">
                <h2 class="padding-top-20 text-uppercase">Página no encontrada</h2>
                <span>Volver a <a routerLink="/">Inicio</a></span>
            </div>
        </div>
    </div>
</div>
