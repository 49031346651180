<div class="login">
    <div class="container">
        <div class="row">
            <div class="col-sm-4 offset-sm-4">
                <div class="box-login">
                    <div class="box-head" style="position: relative;">
                        <img src="assets/img/logos/logo-rapidask-login-white.png">
                        <div style="position: absolute;right: 0px;bottom: 1px">
                         <span  class="test" *ngIf="modoPrueba">PRUEBA</span>
                        </div>                       
                    </div>
                    <div class="box-body">
                        <form [formGroup]="form" (ngSubmit)="login(form.value)">
                            <mat-form-field class="input-container">
                                <input matInput type="text"
                                       placeholder="{{'Usuario'}}"
                                       formControlName="user">
                                <mat-error *ngIf="!form.controls.user.valid && form.controls.user.touched">
                                    {{'Campo requerido'}}
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field class="input-container">
                                <input id="pass" matInput type="password"
                                       placeholder="{{'Password'}}"
                                       formControlName="pass">
                                <mat-error *ngIf="!form.controls.pass.valid && form.controls.pass.touched">
                                    {{'Campo requerido'}}
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field class="input-container" *ngIf="modoPrueba">
                                    <input matInput type="text" [required]="modoPrueba"
                                           placeholder="Ingrese el texto prueba"
                                           formControlName="modo">
                                    <mat-error *ngIf="!form.controls.modo.valid && form.controls.modo.touched">
                                        {{'Campo requerido'}}
                                    </mat-error>
                                </mat-form-field>

                            <button mat-raised-button type="submit" class="margin-top-10" [disabled]="validar()">
                                {{'Entrar'}}
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
