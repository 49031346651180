import {Component} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { QxFilterDialog } from '../qx-filter.base';
import * as moment from 'moment';
import { DATE_FORMAT_API, DATE_FORMAT_VIEW } from '../../../config/config';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-qx-filter-date',
  templateUrl: './qx-filter-date.component.html',
  styleUrls: ['./qx-filter-date.component.scss']
})
export class QxFilterDateComponent extends QxFilterDialog {
    form: FormGroup;

    constructor(public dialogRef: MatDialogRef<any>, public fb: FormBuilder) {
      super();
        this.form = this.fb.group({
            minDate: [null, [Validators.required]],
            maxDate: [null, [Validators.required]],
        });
    }

    keyPress(event) {
        if (event.key == 'Enter' && this.form.valid) {
            this.acepted();
        }
    }

    acepted() {
        this.acept = true;
        this.dialogRef.close();
    }

    get value(): string {
      const startDate = moment(this.form.value.minDate);
      const endDate = moment(this.form.value.maxDate);        
      
      return startDate.format(DATE_FORMAT_VIEW) + ' - ' + endDate.format(DATE_FORMAT_VIEW);
    }

    patchParams(params: any) {
      const startDate = moment(this.form.value.minDate);
      const endDate = moment(this.form.value.maxDate);

      params['startDate'] = startDate.format(DATE_FORMAT_API);
      params['endDate'] = endDate.format(DATE_FORMAT_API);
    }
}
