import {Component, OnInit, Input} from '@angular/core';
import {Router} from '@angular/router';
import {User} from '../../models/user';
import {AppUtils} from '../../helpers/app.utils';
import {NavigationService} from '../../services/navigation.service';
import { timeout } from 'rxjs/operators';
import { configCustom } from '../../../modules/config/config';

declare const $;

@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss'],

})
export class MenuComponent implements OnInit {
    menuItems = [];
    currentUser: User;
    moduleName = '';
    currentModule: any = {};

    constructor(public router: Router, public navigation: NavigationService) {
        this.router.events.subscribe((event: any) => {            
            //$('ul.mm-show').removeClass('mm-show');
            this.activeMenu(this.menuItems);
        });
    }

    ngOnInit() {
        this.currentUser = AppUtils.currentUser();        
        this.navigation.menuItems.subscribe((res: any) => {            
            if (!res) {
                res = this.navigation.activeModule;
            } else {
                let item = res.menu[0];
                if (item.childrens) {
                    item = item.childrens[0];
                }
                this.router.navigateByUrl(item.url);
            }            
            
            this.menuItems = res.menu;
            this.moduleName = res.title || res.name;
            document.title = this.moduleName +" | "+configCustom.projectName;
            this.loadMenu();
            this.currentModule = res;            
        });        
    }

    loadMenu() {
        //this.activeMenu(this.menuItems);
        this.menuItems.forEach(function (element, index) {
            element.active = element.url == this.router.url;
            if (element.childrens) {
                const active = element.childrens.filter(x => x.url == this.router.url);
                if (active.length) {
                    active[0].active = true;
                    element.active = true;
                    this.dropdownMenu('.link-' + index);
                }
            }
        }.bind(this));
    }

    activeMenu(items) {
        if (items) {
            items.forEach(item => {
                item['active'] = !AppUtils.isNullOrUndefined(item.url) && item.url == this.router.url;                
                if (item.childrens) {
                    let isModuleActive = false;
                    item.childrens.forEach(x => {
                        x.active = x.url == this.router.url;
                        if (x.active) {
                            isModuleActive = true;
                        }
                    });
                    item.active = isModuleActive;
                }
            });
        }
    }

    dropdownMenu(element): void {
        const $item = $(element).parent();        
        if (!$item.hasClass('open')) {
            $item.parent().find('.open .submenu').slideUp('fast');
            $item.parent().find('.open').toggleClass('open');
        }
        $item.toggleClass('open');
        if ($item.hasClass('open')) {
            $item.children('.submenu').slideDown('fast');
        } else {
            $item.children('.submenu').slideUp('fast');
        }
    }

    isModuleActive(module) {
        return module.id == this.currentModule.id;
    }

    initMetisMenu() {
        $("#metismenu").metisMenu();        
    }
}