<div class="toolbar-user" (furyClickOutside)="onClickOutside()" fxFlex="grow">
    <button class="button" mat-button (click)="toggleDropdown()" [class.open]="isOpen" fxFlex="grow">
    <span fxLayout="row" fxLayoutAlign="start center">
        <mat-icon>perm_identity</mat-icon>
      <span class="name" fxHide fxShow.gt-sm>{{currentUser?.username}}</span>
      <mat-icon class="icon" fxHide fxShow.gt-sm>keyboard_arrow_down</mat-icon>
    </span>
  </button>

  <div class="dropdown" [class.open]="isOpen" style="width: 216px;">
    <div class="content">
      <div class="list">
       <!--  <div class="list-item" matRipple>
          <mat-icon class="list-item-icon">account_circle</mat-icon>
          <span>Perfil</span>
        </div>
        <div class="list-item" matRipple>
          <mat-icon class="list-item-icon">settings</mat-icon>
          <span>Configuración</span>
        </div> -->       
         <!--  <div *ngIf="esNotaria()" style="width: 216px;"  (click)="cambiarUsuario()" class="list-item" matRipple>
            <mat-icon class="list-item-icon">person</mat-icon>
            <span style="padding-left: 5px;">Cambiar Usuario/Correo</span>
          </div> -->
          <div style="    width: 216px;"  (click)="cambiarContrasena()" class="list-item" matRipple>
            <mat-icon class="list-item-icon">vpn_key</mat-icon>
            <span style="padding-left: 5px;">Cambiar Contraseña</span>
          </div>
       <!--  <div style="    width: 216px;" *ngIf="verAyuda" (click)="ayuda()" class="list-item" matRipple>
          <mat-icon class="list-item-icon">help</mat-icon>
          <span style="padding-left: 5px;">Ayuda</span>
        </div> -->
        <mat-divider style="width: 216px;"></mat-divider>
        <div style="width: 216px;" class="list-item" matRipple (click)="logout()">
          <mat-icon class="list-item-icon">exit_to_app</mat-icon>
          <span style="padding-left: 5px;">Cerrar Sesión</span>
        </div>
      </div>
    </div>
  </div>
</div>
