import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormControl} from '@angular/forms';
import {AppUtils} from '../../helpers/app.utils';
import { QxFilterListComponent } from './qx-filter-list/qx-filter-list.component';
import { QxFilterDateComponent } from './qx-filter-date/qx-filter-date.component';
import { QxFilterConfig, QxFilterDialog } from './qx-filter.base';
import { QxFilterInputComponent } from './qx-filter-input/qx-filter-input.component';
import $ from 'jquery';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'qx-filter',
    templateUrl: './qx-filter.component.html',
    styleUrls: ['./qx-filter.component.scss']
})
export class QxFilterComponent {
    chipSelect: any[] = [];
    filterCtrl: FormControl;
    addOnBlur: boolean = true;

    _filters: Array<QxFilterConfig> = [];

    @Input() title : string;

    @Input()
    set filters(value: Array<QxFilterConfig>) {
        this._filters = value || [];
        this._filters.forEach(item => {            
            item.id = item.id || item.name;
        });
    }

    get params(): any {
        let params = {};
        
        this.chipSelect.forEach(f => {
            for (let key in f.param) {
                params[key] = f.text ? f.text : f.param[key];
            }
        });

        return params;
    }

    get filters(): Array<QxFilterConfig> {
        return this._filters;
    }

    @Output() change: EventEmitter<any>;    

    constructor(public dialog: MatDialog) {
        this.change = new EventEmitter<any>();
        this.change.subscribe(_ => this.add(null));

        this.filterCtrl = new FormControl();
        this.filterCtrl.valueChanges.subscribe(res => {
            if (!AppUtils.isObject(res) && this.filters && this.filters.length > 0 && this.filters[0].type == 'search') {
                this.filters[0]['value'] = res;
                this.filters[0]['text'] = res;
            }
        });
    }

    selectedOption(item: any) {
        $('#filtro').blur();
        $('#foco').blur();
        const option: any = item.option;
        if (!option) {
            return false;
        }

        const chip = AppUtils.clone(option.value);  
        let inChip: any = this.chipSelect.filter(x => x.id == chip.id);
        inChip = inChip.length ? inChip[0] : null;

        let dialog: MatDialogRef<QxFilterDialog> = null;

        switch(chip.type) {
            case 'list': {
                dialog = this.dialog.open(QxFilterListComponent, {
                    disableClose:true, 
                    data: chip.options
                });

                const dialogInstance = (dialog.componentInstance as QxFilterListComponent);
                dialogInstance.items = chip.options.list;
                dialogInstance.url = chip.options.url;
                dialogInstance.keyId = chip.options.keyId;
                dialogInstance.keyValue = chip.options.keyValue;
                dialogInstance.name = chip.options.name;
                dialogInstance.allParams = this.params;
                
                break;
            }

            case 'date': {
                dialog = this.dialog.open(QxFilterDateComponent, {
                    disableClose:true, 
                });                
                break;
            }

            case 'input': {
                dialog = this.dialog.open(QxFilterInputComponent, {
                    disableClose:true, 
                });
                
                break;
            }

            case 'custom': {
                dialog = this.dialog.open(option.value.component, {
                    disableClose:true, 
                });
                break;
            }

            case 'search': {
                if (!this.filters[0]['text']) {
                    return false;
                }

                this.filters[0]['text'] = '';
                
            }           

            default: {
                if (inChip) {
                    const index = this.chipSelect.indexOf(inChip[0]);
                    this.chipSelect[index] = chip;
                } else {
                    this.chipSelect.push(chip);
                }
                
                this.change.emit(this.chipSelect);
                break;
            }
        }

        if (dialog) {
            dialog.componentInstance.title = chip.name;            

            dialog.afterClosed().subscribe(res => {
                if (dialog.componentInstance.acept) {
                    let value = dialog.componentInstance.value;
                    if (chip.label) {
                        value = chip.label + ': ' + value;
                    }

                    const mChip = inChip || chip;                        
                    mChip.value = value;
                    dialog.componentInstance.patchParams(mChip.param);                        
                    
                    if (!inChip) {
                        this.chipSelect.push(chip);
                    }
                    
                    this.change.emit(this.chipSelect);
                }
            });
        }
    }

    remove(item): void {      
        let index = this.chipSelect.indexOf(item);     
        if (index >= 0) {
            this.chipSelect.splice(index, 1);          
            this.change.emit(this.chipSelect);
        }
    }

    add(event): void {
        $("#filtro").val('');
        
    }
}
