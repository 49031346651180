import {
    Component, Input, AfterViewInit, Output, EventEmitter, ViewChild
} from '@angular/core';
import { QxFilterComponent } from '../qx-filter/qx-filter.component';
import { CurrencyPipe } from '@angular/common';
import { QxFilterConfig } from '../qx-filter/qx-filter.base';
import { AppUtils } from '@quiox/global/helpers/app.utils';

@Component({
    selector: 'qx-table',
    templateUrl: './qx-table.component.html',
    styleUrls: ['./qx-table.component.scss']
})
export class QxTableComponent implements AfterViewInit {
    @Input() list_items: any[] = [];
    @Input() headers: any[];
    @Input() headerView: {};
    @Input() permissions;
    @Input() currentItems;
    @Input() itemperpage;
    @Input() currentpage;
    @Input() totalItems;
    @Input() totalPage;
    @Input() filters: Array<QxFilterConfig>;
    @Input() title;
    labels: string[];
    keys: string[] = [];
    rowClass = (item) => { return ''; }

    @Output('detail') OnDetail: EventEmitter<any>;
    @Output('update') OnUpdate: EventEmitter<any>;
    @Output('removeConfirm') OnRemoveConfirm: EventEmitter<any>;
    @Output('search') OnSearch: EventEmitter<any>;
    @Output() currentpageChange: EventEmitter<any>;
    @Output() itemperpageChange: EventEmitter<any>;

    @ViewChild(QxFilterComponent, {static: false}) filterComponent: QxFilterComponent;

    constructor() {
        this.list_items = [];
        this.headers = [];
        this.headerView = {field: false, link: false, field_view: 'id'};
        this.labels = [];
        this.keys = [];
        this.OnDetail = new EventEmitter();
        this.OnUpdate = new EventEmitter();
        this.OnRemoveConfirm = new EventEmitter();
        this.OnSearch = new EventEmitter();
        this.currentpageChange = new EventEmitter();
        this.itemperpageChange = new EventEmitter();
        this.permissions = {};
        this.currentItems = 0;
        this.totalItems = 0;
        this.totalPage = 0;
    }

    ngAfterViewInit() {
        let headers_item = this.headers;
        for (let key in headers_item) {
            this.labels.push(key);
            this.keys.push(headers_item[key]);
        }        
    }

    update(e) {
        this.OnUpdate.emit(e);
    }

    detail(e) {
        this.OnDetail.emit(e);
    }

    searchByFilters(e) {       
        this.currentpage = 0;
        this.search(e);
    }

    search(e) {
        if (!(e instanceof Event)) {
            this.currentpageChange.emit(this.currentpage);
            this.itemperpageChange.emit(this.itemperpage);
            this.OnSearch.emit(e);
        }
    }

    removeConfirm(obj, title) {
        let e = {item: obj, title: title};
        this.OnRemoveConfirm.emit(e);
    }

    getValue(item: any, property: string) {
        let arr = property.split("|");
        property = arr[0].trim();
        let pipeName = arr.length > 1 ? arr[1].trim() : null;

        let value = item;
        property.split('.').forEach(prop => value = value ? value[prop] : value);

        if (AppUtils.isBoolean(value)) {
            return value ? 'Sí' : 'No';
        }

        if (pipeName == 'currency') {
            let pipe = new CurrencyPipe('en_US');
            return pipe.transform(value);
        }

        return value;
    }   
    
    isEmpty(){
       return this.totalItems ==0;
    }
}
