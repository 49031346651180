import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { from, Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { APP_CONFIG } from '../../app.config';
import { Notifications } from '../helpers/notifications';
import { AppUtils } from '../helpers/app.utils';




@Injectable()
export class DefaultOAuthInterceptor implements HttpInterceptor {

    constructor(private router: Router) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let url = request.url
        //.toLowerCase();
        console.log("interceptor", url);

        let secure = true;

        if (!url.startsWith("/")) {
            url = '/' + url;
        }

        if (url.includes('oauth') || url.includes('app-info') || url.includes('assets')) {
            secure = false;
        }

        const config = APP_CONFIG[APP_CONFIG.active_profile];
        const prefix = config.url_gateway;
        if (url.includes('api/dashboard-data') || url.includes('api/invoice-data') || url.includes('api/notifications-data')) {

        } else if (!url.includes(prefix)) {
            console.log("agregarndo url", url)
            request = request.clone({ url: prefix + url });
            // request = request.clone({ url: `https://r04kuphoih.execute-api.us-east-1.amazonaws.com/test${url}` });
        }


        return of(secure)
            .pipe(
                switchMap(secure => secure === true ? from(this.getCurrentSession()) : of(false)),
                map((auth: any) => this.checkOAuthToken(auth, request)), // switchMap() is used instead of map().
                switchMap(req => next.handle(req)),
                catchError(err => {
                    const notification = new Notifications();
                    notification.verification(err);
                    return throwError(err);
                })
            );

    }




    private getCurrentSession(): Promise<any> {
        return new Promise((resolve, reject) => {
            resolve(AppUtils.load("access_token"))
        });
    }

    private checkOAuthToken(auth, request): HttpRequest<any> {
        // console.log('Auth:', auth);
        if (auth !== false) {
            //  const jwt = auth.accessToken.jwtToken;
            const jwt = auth;
            const with_auth_request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${jwt}`
                }
            });
            // console.log("Cloned", with_auth_request);
            return with_auth_request;
        }
        return request;
    }



}


