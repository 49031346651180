<div class="col-sm-12 row">
       <mat-label class="col-sm-12">{{ placeholder }} {{ required ? '*' : ''}}</mat-label>

       <mat-select placeholder="horas" class="col-sm-4" [formControl]="hoursControl">
              <mat-option *ngFor="let i of hList" [value]="i">
                     {{ procesar(i) }}
              </mat-option>
       </mat-select>       

       <mat-label class="col-sm-1"> : </mat-label>

       <mat-select placeholder="minutos" class="col-sm-4" [formControl]="minutesControl" >
              <mat-option *ngFor="let i of mList" [value]="i">
                     {{ procesar(i) }}
              </mat-option>
       </mat-select>

       <mat-select class="col-sm-3"  [formControl]="ampmControl" >
              <mat-option value="AM">AM</mat-option>
              <mat-option value="PM">PM</mat-option>
       </mat-select>
</div>