import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AppUtils } from '../helpers/app.utils';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { config } from '../config/config';
import { User } from '../models/user';
import { SnackBarService } from '../helpers/snack-bar.service';
import { SidenavService } from 'src/app/layout/sidenav/sidenav.service';
import { SidenavItem } from 'src/app/layout/sidenav/sidenav-item/sidenav-item.interface';
import { configCustom } from '../../modules/config/config';

@Injectable()
export class NavigationService {
    modules = configCustom.modules.concat(config.modules);

    public static smallViewportWidth: number = 600;
    public static largeViewportWidth: number = 992;

    public _pageTitle: BehaviorSubject<string> = new BehaviorSubject(null);
    public _menusItems: BehaviorSubject<any> = new BehaviorSubject(null);
    public _currentRoute: BehaviorSubject<string> = new BehaviorSubject(null);
    public _windowSize: BehaviorSubject<number> = new BehaviorSubject(NavigationService.largeViewportWidth);
    public _showSidenav: BehaviorSubject<boolean> = new BehaviorSubject(this.largeScreen);
    public activeTab = AppUtils.load('activeTab') || 0;

    constructor(private http: HttpClient, private router: Router,
        private snackBarService:SnackBarService,
        private sidenavService: SidenavService) {
    }

    public get pageTitle(): BehaviorSubject<string> {
        return this._pageTitle;
    }

    public setPageTitle(title: string): NavigationService {
        this._pageTitle.next(title);
        return this;
    }

    public get menuItems(): BehaviorSubject<any[]> {
        return this._menusItems;
    }

    public setMenuItems(menus: any): NavigationService {
        this._menusItems.next(menus);
        return this;
    }

    public setMenuItemsFury(menus: SidenavItem[]): NavigationService {      
        this.sidenavService.addItems(menus);
        return this;
    }

    public cleanMenuItem(){
        this.sidenavService.clearItems();
    }   

    public get currentRoute(): BehaviorSubject<string> {
        return this._currentRoute;
    }

    public setCurrentRoute(route: string): NavigationService {
        this._currentRoute.next(route);
        return this;
    }

    public get windowSize(): BehaviorSubject<number> {
        return this._windowSize;
    }

    public updateViewport(): NavigationService {
        this._windowSize.next(NavigationService.viewport().width);
        return this;
    }

    public get showSidenav(): BehaviorSubject<boolean> {
        return this._showSidenav;
    }

    public toggleSidenav(showSidenav: boolean): NavigationService {
        this._showSidenav.next(showSidenav);
        return this;
    }

    public get mediumScreenAndDown(): boolean {
        return window !== undefined ? window.matchMedia(`(max-width: ${NavigationService.largeViewportWidth}px)`).matches : false;
    }

    public get mediumScreenAndUp(): boolean {
        return window !== undefined ? window.matchMedia(`(min-width: ${NavigationService.smallViewportWidth}px)`).matches : false;
    }

    public get smallScreen(): boolean {
        return window !== undefined ? window.matchMedia(`(max-width: ${NavigationService.smallViewportWidth}px)`).matches : false;
    }

    public get mediumScreen(): boolean {
        return window !== undefined ? (!this.smallScreen && !this.largeScreen) : false;
    }

    public get largeScreen(): boolean {
        return window !== undefined ? window.matchMedia(`(min-width: ${NavigationService.largeViewportWidth}px)`).matches : false;
    }

    public static viewport(): ViewPort {
        if (!window) {
            return { width: this.smallViewportWidth, height: 500 };
        }
        let e: any = window;
        let a: string = 'inner';
        if (!('innerWidth' in window)) {
            a = 'client';
            e = document.documentElement || document.body;
        }
        return { width: e[a + 'Width'], height: e[a + 'Height'] };
    }

    public casAuth() {
        this.router.navigateByUrl('login');
    }

    public config() {        
        let modules: any = config.modules.concat(configCustom.modules);  
       
        modules = AppUtils.clone(modules);  
        console.log("modulos",modules)     
        this.modules = modules.filter((module: any) => {
            module.menu = module.menu.filter((item: any) => {
                if (item) {    
                    console.log("item menu", item)              
                      return AppUtils.verifyAnyPerm(item.roles as []);
                }               
            });
            console.log("modulos filtrados",module.menu)    
            return module.menu.length > 0;
        });
    }

    get activeModule() {
        return this.modules[this.activeTab];
    }

    get firstOption() {
        if (!this.activeModule.menu.length) {
            this.snackBarService.error('Usted no tiene acceso a esta aplicación.');        
            return null;
        }

        const item: any = this.activeModule.menu[1];       
        return item.routeOrFunction;
    }

    get allModules() {
        return this.modules;
    }
}

interface ViewPort {
    width: number;
    height: number;
}