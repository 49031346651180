import { Type } from '@angular/core';

export class QxFilter {
    constructor() {}

    get value(): string {
        return '';
    }

    patchParams(params: any) {

    }
}

export class QxFilterDialog extends QxFilter {
    title: string;
    acept: boolean;
    
    constructor() {
        super();
    }
}

export interface QxFilterConfig {
    id?: string;    
    type: string;
    name: string;
    label?: string;
    value?: any;        
    param: any;
    component?: Type<QxFilterDialog>;
    options?: any;
}