import { ModuleFuryConfig } from '@quiox/global/config/config';

const encuesta_cas = 'http://localhost:8010/auth/login';

export const usuariomenu : ModuleFuryConfig = {       
    name: 'Panel de Control', 
    menu: [
        {
            name: 'Panel de Control',
            position: 10,
            type: 'subheading',
            customClass: 'first-subheading',
            roles: [
                'ROLE_ENCUESTADOR',
                'ROLE_SUPERVISOR',
                'ROLE_CLIENT_OWNER'
            ]
          },
          {
            name: 'Encuestas',
            routeOrFunction: '/user/formulario/list',
            icon: 'format_list_bulleted',
            position: 15,
            roles: [
                'ROLE_ENCUESTADOR',
                'ROLE_SUPERVISOR',
                'ROLE_CLIENT_OWNER'
            ]
          },
       
    ]
        
};
