<div mat-dialog-title class="col-sm-12">
        {{title}}
      <mat-icon  style="cursor:pointer; float: right;padding-left: 223px;" (click)="dialogRef.close()">close</mat-icon> 
</div>

<mat-dialog-content>
        <form [formGroup]="form" class="padding-top-10">
            <mat-form-field class="input-container col-sm-12">
                <input autofocus matInput [matAutocomplete]="filterListAuto" placeholder="{{name}}" formControlName="value" (keypress)="keyPress($event)">
                <mat-autocomplete #filterListAuto="matAutocomplete"  [displayWith]="displayFn" (optionSelected)="selectedOption($event)">
                    <mat-option *ngFor="let item of itemsReactives | async" [value]="item">
                        <span>{{ item[keyValue] }}</span>
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </form>
    </mat-dialog-content>

    <mat-dialog-actions fxLayout="row" fxLayoutAlign="end center" style="padding-bottom: 20px;">
            <button type="button" mat-button (click)="dialogRef.close()">
                CANCELAR
            </button>
            <button [disabled]="!form.valid" type="button" mat-button mat-raised-button color="primary" style="color: #ffff;cursor: pointer;"
                (click)="acepted()">
                ACEPTAR
            </button>
        </mat-dialog-actions>