import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { AppUtils } from '../../global/helpers/app.utils';

@Component({
  selector: 'fury-quickpanel',
  templateUrl: './quickpanel.component.html',
  styleUrls: ['./quickpanel.component.scss']
})
export class QuickpanelComponent implements OnInit {

  todayDay: string;
  todayDate: string;
  todayDateSuffix: string;
  todayMonth: string;
  verAyuda:boolean=false;
  permisoCrear:boolean=false;

  constructor(public router:Router) { }

  ngOnInit() {
    this.todayDay = moment().format('dddd');
    this.todayDate = moment().format('Do');
    this.todayDate = this.todayDate.replace(/\D/g, '');
    this.todayDateSuffix = moment().format('Do');
    this.todayDateSuffix = this.todayDateSuffix.replace(/[0-9]/g, '');
    this.todayMonth = moment().format('MMMM');

    this.verAyuda= AppUtils.verifyPerm("ROLE_VER_AYUDA") || AppUtils.verifyPerm("ROLE_MASTER");
    this.permisoCrear=AppUtils.verifyPerm("ROLE_GESTIONAR_SOLICITUD_TRAMITE") || AppUtils.verifyPerm("ROLE_MASTER") ;
  }

  inicio() {
    const url: string = '/webapp';
    this.router.navigateByUrl(url);
  }

  crear() {
    const url: string = '/webapp/tramite/solicitud-tramite/crear';
    this.router.navigateByUrl(url);
  }

  ayuda() {
    const url: string = '/webapp/ayuda';
    this.router.navigateByUrl(url);
  }

}
