import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AppUtils } from '@quiox/global/helpers/app.utils';




@Injectable({ providedIn: 'root' })
export class LoginGuard implements CanActivate {
 
  constructor() {     
  }
 
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {   
    
    AppUtils.logout(); 
    
    return true;
  }
}
