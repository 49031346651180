import {
    Component, OnInit, Inject
} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
    selector: 'app-dialog-alert',
    templateUrl: './dialog-alert.component.html',
    styleUrls: ['./dialog-alert.component.scss']
})
export class DialogAlertComponent implements OnInit {
    saved = false;
    form: FormGroup;   
    vuelto: number = 0;
    message: any;
    estado:boolean = false;

    constructor(public dialogRef: MatDialogRef<any>,
                public dialog: MatDialog,                
                public fb: FormBuilder, @Inject(MAT_DIALOG_DATA) public data: any) {
        this.message = data.message;
        this.estado = data.estado;        
    }

    ngOnInit() {
        this.form = this.fb.group({
            message:  this.message
          });          
    }

    salir() {
        this.dialogRef.close();
    }

    keyPress(event) {
      if (event.key == 'Enter') {
          this.save();
      }
    }

    save() {
        this.saved = true;
        this.dialogRef.close();
    } 
}
