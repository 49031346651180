import { Component, OnInit } from '@angular/core';
import { User } from '../../../global/models/user';
import { AppUtils } from '../../../global/helpers/app.utils';
import { Router } from '@angular/router';
import { AuthenticationService } from '@quiox/global/services/authentication.service';
import { SnackBarService } from '@quiox/global/helpers/snack-bar.service';
import { Spin } from '@quiox/global/helpers/spin';
import { NavigationService } from '@quiox/global/services/navigation.service';

@Component({
  selector: 'fury-toolbar-modules',
  templateUrl: './toolbar-modules.component.html',
  styleUrls: ['./toolbar-modules.component.scss']
})
export class ToolbarModulesComponent implements OnInit {

  isOpen: boolean;
  currentUser: User;
  verAyuda: boolean = false;
  spin: Spin
  showMenu: boolean = true;
  modules = [];

  constructor(public router: Router,public auth: AuthenticationService, public snackBarService: SnackBarService,public navigation: NavigationService, ) { 
    this.spin = new Spin();
  }

  ngOnInit() {
    this.currentUser = AppUtils.currentUser();        
    this.modules = this.navigation.allModules;      
   }

   selectModule(external = false,index) {
    const module = index;
    if (!AppUtils.isNullOrUndefined(module)) {
        AppUtils.save('activeTab', this.navigation.activeTab); 
        this.navigation.cleanMenuItem();           
        this.navigation.setMenuItemsFury(module.menu); 
        const firstMenu = this.selectFirstMenuShow(module.menu);          
        if (!AppUtils.isNullOrUndefined(firstMenu)) {
            if (external){
              console.log("entrooo",firstMenu.routeOrFunction)
              this.router.navigateByUrl(firstMenu.routeOrFunction);
              this.isOpen=false;
            }
            else {
                let index = -1;
                for (let i in this.modules) {
                  //  const urlOk = this.urlOk(this.modules[i].menu);
                  //  if (urlOk) {
                        index = parseInt(i);
                  //  }
                }
                
                if (index >= 0)
                    this.navigation.activeTab = index;
            }
        }
    }
}

selectFirstMenuShow(menus) {  
    var primermenu = null;
    for (let index = 0; index < menus.length; index++) {      
        const element = menus[index];
        if(element.routeOrFunction){     
            primermenu = element;
            break;
        }
    }

   
    return primermenu;
}

urlOk(menus) {
    let urlOk = menus.filter(m => this.router.url.indexOf(m.url) > -1 && m['show']);
    if (!urlOk.length) {
        menus.forEach(m => {
            if (m.childrens) {
                urlOk = this.urlOk(m.childrens);
                if (urlOk) {
                    return urlOk;
                }
            }
        });
    }
    return urlOk.length;
}

findAuthorityMenu(authority, menu) {
    let access = false;
    menu.forEach(item => {
        if (item.roles && item.roles.indexOf(authority) >= 0) {
            access = true;
            item['show'] = true;
        } else {
            item['show'] = false;
        }
        if (item.childrens) {
            access = this.findAuthorityMenu(authority, item.childrens);
            item['show'] = access;
        }
    });
    return access;
}
  toggleDropdown() {
    this.isOpen = !this.isOpen;
  }

  onClickOutside() {
    this.isOpen = false;
  }


 

}
