import {Directive, HostListener, Input} from '@angular/core';
import { ResourceRemoteService } from './resource.remoteservice';

export interface Resource {    
    name: string;
}

@Directive({
    selector: '[appLazyDownload]'
})
export class LazyDownloadDirective {
    @Input() resourceInfo: Resource;

    constructor(private resourceRemoteService: ResourceRemoteService) {
    }

    @HostListener('click', ['$event'])
    public onClick(evt: MouseEvent): void {
        evt.preventDefault();
        evt.stopPropagation();
        this.resourceRemoteService.getUrl(this.resourceInfo.name).subscribe(url => {
            this.downloadResourse(this.resourceInfo.name, url);
        });
    }

    private downloadResourse(fileName: any, url: string): void {
        window.open(url,'_blank');
    }

    private useLinkToDownload(fileName: any, url: string): void {
        this.resourceRemoteService.getContent(url).subscribe(data => {
            // const blob = new Blob([data], {type: data.type});
            const isPdf = this.isPdf(fileName);
            const blob = new Blob([data], {type: isPdf ? 'application/pdf' : 'application/octet-stream'});

            // iexplore and edge
          /*  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(blob, fileName);
                return;
            }*/

            const objUrl = window.URL.createObjectURL(blob);
            const linkElement = document.createElement('a');
            linkElement.href = objUrl;
            linkElement.setAttribute('style', 'display: none');
            if (isPdf) {
                linkElement.setAttribute('target', '_blank');
            } else {
                linkElement.download = fileName;
            }
            document.body.appendChild(linkElement);
            linkElement.click();
            setTimeout(() => {
                linkElement.parentNode.removeChild(linkElement);
                // document.body.removeChild(a);
                window.URL.revokeObjectURL(objUrl);
            }, 100);

            // const pwa = window.open(objUrl);
            // if (!pwa || pwa.closed || pwa.closed === undefined) {
            //     alert('Please disable your Pop-up blocker and try again.');
            // }
        });
    }

    private isPdf(fileName: string): boolean {
        return fileName.endsWith('.pdf');
    }

    // private useLinkToDownload1(url: string): void {
    //     const linkElement = document.createElement('a');
    //     linkElement.href = url; // + '?file-name=' + resource.name;
    //     linkElement.setAttribute('target', '_blank');
    //     // linkElement.setAttribute('download')
    //     document.body.appendChild(linkElement);
    //     linkElement.click();
    //     linkElement.parentNode.removeChild(linkElement);
    // }

}
