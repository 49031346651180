import { administracionmenu } from '../administracion/config/config';
import { usuariomenu } from '../usuario/config/config';
import { clientemenu } from '../cliente/config/config';
import { supervisormenu } from '../supervisor/config/config';


export const configCustom = {
    projectName: 'Encuestas',
    modules: [
        administracionmenu,
        usuariomenu,
        supervisormenu
       /*  clientemenu     */  
       
    ]
};