import {Component} from '@angular/core';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import { QxFilterDialog } from '../qx-filter.base';
import { QxFilterListService } from './qx-filter-list.service';
import { MatDialogRef } from '@angular/material/dialog';
import { AppValidators } from '../../../helpers/app.validators';
import { AppUtils } from '@quiox/global/helpers/app.utils';

@Component({
  selector: 'app-qx-filter-list',
  templateUrl: './qx-filter-list.component.html',
  styleUrls: ['./qx-filter-list.component.scss']
})
export class QxFilterListComponent extends QxFilterDialog  {
    items: any[] = [];
    itemsReactives: any;    
    name: any;
    keyId: any;
    keyValue: any;
    selected: any;    
    url: any;
    form: FormGroup;
    allParams: any;

    constructor(public dialogRef: MatDialogRef<any>, public filterlistService: QxFilterListService, fb: FormBuilder) {
      super();

        this.form = fb.group({
            value: [null, [Validators.required, AppValidators.isAnObject]]
        });

        this.itemsReactives = this.filterStates('');
        this.form.get('value').valueChanges.subscribe(res => {
            if (!AppUtils.isObject(res)) {
                this.itemsReactives = this.filterStates(res);
            }
        });

        setTimeout(_ => this.form.get('value').setValue(''));
    }

    async filterStates(val: string) {
        if (AppUtils.isNullOrUndefined(this.url)) {
            return val ? this._filter(this.items, val) : this.items;
        } else {
            val = !AppUtils.isNullOrUndefined(val) ? val : '';
            const vialidades = await this.filterlistService.get(this.url, val, this.allParams);
            return vialidades;
        }
    }

    displayFn(value: any): string {
        return value && typeof value === 'object' ? (value.nombre || value.nombreCompleto) : value;
    }

    private _filter(states, val: string) {
        const filterValue = val.toLowerCase();
        return states.filter(state => state[this.keyValue].toLowerCase().indexOf(filterValue) >= 0);
    }

    selectedOption(item) {
        this.selected = item.option.value;
    }

    get value(): string {
        return this.selected ? this.selected[this.keyValue].toString() : '';
    }

    patchParams(params: any) {
        var value = this.selected || {};
        for (let i in params) {
            params[i] = value[this.keyId];
        }
    }

    keyPress(event) {
        if (event.key == 'Enter' && this.form.valid) {
            this.acepted();
        }
    }

    acepted() {
        this.acept = true;
        this.dialogRef.close();
    }

  

}
