import {HttpResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {Notifications} from '../helpers/notifications';
import {ProgressBarService} from '../services/progress-bar.service';
import { Router } from '@angular/router';
import { AppUtils } from '../helpers/app.utils';
import { Spin } from '../helpers/spin';

export abstract class OAuthResourceServerErrorHandler {
    abstract handleError(err: HttpResponse<any>): Observable<any>;
}

export class OAuthNoopResourceServerErrorHandler
    implements OAuthResourceServerErrorHandler {
    
    constructor(private progressBarService: ProgressBarService, private router: Router) {
    }

    handleError(err: HttpResponse<any>): Observable<any> {
        if (err.url.indexOf('login') > 0) {
            new Spin().spinStop();
            AppUtils.logout();
            this.router.navigateByUrl('webapp/login');
            
            return throwError(err);
        }

        const notification = new Notifications();
        notification.verification(err);
        this.progressBarService.decrease();
        return throwError(err);
    }
}
