import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from './layout/layout.component';
import { AuthGuardService } from './global/guard/auth.guard';
import { LoginGuard } from './modules/autenticacion/call-back-cognito/login.guard';
const routes: Routes = [
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full'
  },
  {path: 'login', pathMatch: 'full', canActivate: [LoginGuard], children: []},
  
  {
    path: 'callback',
    loadChildren: () => import('./modules/autenticacion/call-back-cognito/call-back-cognito.module').then(m => m.CallBackModule),
  },
 /*  {
    path: 'login',
    loadChildren: () => import('./pages/authentication/login/login.module').then(m => m.LoginModule),
    canActivate: [AuthGuardService]
  }, */
  {
    path: 'forgot-password',
    loadChildren: () => import('./pages/authentication/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule),
  },
  {
    path: 'forgot-password/change/:token',
    loadChildren: () => import('./pages/authentication/register/register.module').then(m => m.RegisterModule),
  },
  {
    path: 'forcing-change-pass',
    loadChildren: () => import('./pages/authentication/forcing-change-pass/forcing-change-pass.module').then(m => m.ForcingChangePassModule),
  },
  {
    path: 'admin',
    loadChildren: () => import('./modules/administracion/administracion.module').then(m => m.AdministracionModule)
  },
  {
    path: 'client',
    loadChildren: () => import('./modules/cliente/cliente.module').then(m => m.ClienteModule)
  },
  {
    path: 'supervisor',
    loadChildren: () => import('./modules/supervisor/supervisor.module').then(m => m.SupervisorModule)
  },
  {
    path: 'user',
    loadChildren: () => import('./modules/usuario/usuario.module').then(m => m.UsuarioModule)
  },
  {
    path: 'estadisticas',
    loadChildren: () => import('./modules/estadisticas/estadisticas.module').then(m => m.EstadisticasModule)
  },

  /**/{
    path: 'demos',
    component: LayoutComponent,
    children: [
      /*{
        path: '',
        loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule),
        pathMatch: 'full'
      },*/
      /*{
        path: 'components',
        loadChildren: () => import('./pages/components/components.module').then(m => m.ComponentsModule),
      },*/
    ]
  }/**/
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled',
    // preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
