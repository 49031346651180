<div class="padding-top-20">
    <!--<banner-title [back]="false" [title]="title"></banner-title>-->
    <mat-card>
        <mat-card-title>
            Mi Perfil
        </mat-card-title>
        <mat-card-content>
            <div class="row">
                <div class="col-sm-6">
                    <strong>Nombre:</strong>
                    <span class="padding-left-10">{{currentUser.nombreUsuario}}</span>
                </div>
            </div>
            <form class="form-row padding-top-15" [formGroup]="form">
                <mat-form-field class="input-container col-sm-12">
                    <input matInput type="password" required
                           placeholder="{{'Contraseña Actual'}}"
                           formControlName="actualClave">
                    <mat-error *ngIf="!form.controls.actualClave.valid && form.controls.actualClave.touched">
                        {{'Campo requerido'}}
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="input-container col-sm-12">
                    <input matInput type="password" required
                           placeholder="{{'Contraseña Nueva'}}"
                           formControlName="nuevaClave">
                    <mat-error *ngIf="!form.controls.nuevaClave.valid && form.controls.nuevaClave.touched">
                        {{'Campo requerido'}}
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="input-container col-sm-12">
                    <input matInput type="password" required
                           placeholder="{{'Confirmar Contraseña'}}"
                           formControlName="nuevaClave2">
                    <mat-error
                            *ngIf="!form.controls.nuevaClave2.valid && form.controls.nuevaClave2.touched">
                        {{'Campo requerido'}}
                    </mat-error>
                </mat-form-field>
                <div class="col-sm-12">
                    <div class="col-sm-12 clearfix">
                        <button mat-raised-button (click)="save(form.value)" class="margin-top-10 pull-right"
                                [disabled]="!form.valid">
                            {{'Guardar'}}
                        </button>
                    </div>
                </div>
            </form>
        </mat-card-content>
    </mat-card>
</div>