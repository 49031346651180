import {Component, OnInit} from '@angular/core';
import {AppUtils} from '../../helpers/app.utils';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {PerfilService} from './perfil.service';
import {Notifications} from '../../helpers/notifications';
import { SnackBarService } from '@quiox/global/helpers/snack-bar.service';

@Component({
    selector: 'app-perfil',
    templateUrl: './perfil.component.html'
})
export class PerfilComponent implements OnInit {
    currentUser: any;
    form: FormGroup;
    notification: Notifications;

    constructor(public fb: FormBuilder,
                public perfilService: PerfilService,private snackBarService:SnackBarService) {
        this.notification = new Notifications();
    }

    ngOnInit() {
        this.currentUser = AppUtils.currentUser();
        this.form = this.fb.group({
            actualClave: [null, Validators.required],
            nuevaClave: [null, Validators.required],
            nuevaClave2: [null, Validators.required],
        });
    }

    save(values): void {
        this.perfilService.update(values).subscribe(res => {
            if (res.success) {
                this.snackBarService.success( (res.message || 'Contraseña actualizada.'));
            } else {
                this.snackBarService.error( res.message);
            }
        }, error1 => {
            this.snackBarService.error(error1.message);
        });
    }
}
