<div class="toolbar-module" (furyClickOutside)="onClickOutside()" fxFlex="grow">
    <button class="button" mat-button (click)="toggleDropdown()" [class.open]="isOpen" fxFlex="grow">
    <span fxLayout="row" fxLayoutAlign="start center">
        <mat-icon>clear_all</mat-icon>
      <span class="name" fxHide fxShow.gt-sm>Módulos</span>
    </span>
  </button>

  <div class="dropdown" [class.open]="isOpen" style="width: 200px;">
    <div class="content">
      <div class="list">
     
        <div style="    width: 200px;" *ngFor="let item of modules; let index">
          <div  (click)="selectModule(true,index)" class="list-item" matRipple>
           <!--  <mat-icon class="list-item-icon">vpn_key</mat-icon>  -->
            <span style="padding-left: 5px;">{{item.name}}</span>
          </div>
      
          <mat-divider style="width: 200px;"></mat-divider>
        </div>
      
      </div>
    </div>
  </div>
</div>
