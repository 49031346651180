<mat-tab-group class="quickpanel" fxFlex>
 <!--  <mat-tab label="Overview">
    <fury-scrollbar class="overflow-container">
      <div class="text-padding">
        <p>TODAY</p>
        <p class="h1">
          <span>{{ todayDay }}</span> <br/>
          <span>{{ todayDate }}</span><span style="font-size: 18px; vertical-align: top;">{{ todayDateSuffix }}</span><span>{{ todayMonth }}</span>
        </p>
      </div>

      <mat-divider></mat-divider>

      <mat-nav-list>
        <h3 matSubheader>UPCOMING EVENTS</h3>
        <a href="javascript:" mat-list-item>
          <h4 matLine>Business Meeting</h4>
          <p matLine>In 16 Minutes, Meeting Room</p>
        </a>
        <a href="javascript:" mat-list-item>
          <h4 matLine>Ask for Vacation</h4>
          <p matLine>12:00 PM</p>
        </a>
        <a href="javascript:" mat-list-item>
          <h4 matLine>Dinner with Sophie</h4>
          <p matLine>18:30 PM</p>
        </a>
        <a href="javascript:" mat-list-item>
          <h4 matLine>Deadline for Project X</h4>
          <p matLine>21:00 PM</p>
        </a>
        <mat-divider></mat-divider>
        <h3 matSubheader>TODO-LIST</h3>
        <a mat-list-item>
          <h4 matLine>Invite Jack to play golf</h4>
          <p matLine>Added: 6 hours ago</p>
        </a>
        <a mat-list-item>
          <h4 matLine>Get to know Angular more</h4>
          <p matLine>Added: 2 days ago</p>
        </a>
        <a mat-list-item>
          <h4 matLine>Configure that new router</h4>
          <p matLine>Added: 5 days ago</p>
        </a>
        <mat-divider></mat-divider>
        <h3 matSubheader>SERVER STATISTICS</h3>
        <a mat-list-item>
          <p matLine>CPU Load (71% / 100%)</p>
          <p class="progress-bar" matLine>
            <mat-progress-bar mode="determinate" color="primary" value="71"></mat-progress-bar>
          </p>
        </a>
        <a mat-list-item>
          <p matLine>RAM Usage (6,175 MB / 16,384 MB)</p>
          <p class="progress-bar" matLine>
            <mat-progress-bar mode="determinate" color="accent" value="34"></mat-progress-bar>
          </p>
        </a>
        <a mat-list-item>
          <p matLine>CPU Temp (43° / 80°)</p>
          <p class="progress-bar" matLine>
            <mat-progress-bar mode="determinate" color="warn" value="54"></mat-progress-bar>
          </p>
        </a>
      </mat-nav-list>
    </fury-scrollbar>
  </mat-tab> -->
  <mat-tab label="Acciones Rápidas">
    <fury-scrollbar class="overflow-container">
      <mat-nav-list>       
        <a href="/webapp" mat-list-item>
          <mat-icon style="font-size: 44px;" mat-list-avatar>dashboard</mat-icon>
          <h3 matLine> Inicio </h3>
          <p matLine>
            <span> Ir al panel de control </span>          
          </p>
        </a>
        <a *ngIf="permisoCrear" href="/webapp/tramite/solicitud-tramite/crear" mat-list-item>
          <mat-icon style="font-size: 44px;" mat-list-avatar>control_point</mat-icon>
          <h3 matLine> Crear Solicitud </h3>
          <p matLine>
            <span> Crear una solicitud de trámite</span>          
          </p>
        </a>
        <a *ngIf="verAyuda" href="/webapp/ayuda" mat-list-item>
          <mat-icon style="font-size: 44px;" mat-list-avatar>help</mat-icon>
          <h3 matLine> Ayuda</h3>
          <p matLine>
            <span> Ir a la ayuda</span>          
          </p>
        </a>
      </mat-nav-list>
    </fury-scrollbar>
  </mat-tab>
</mat-tab-group>
