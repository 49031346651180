import { MatSnackBar } from "@angular/material/snack-bar";
import { NgZone, Injectable } from "@angular/core";
import { AppUtils } from "./app.utils";

@Injectable({
    providedIn: 'root'
})
export class SnackBarService {
    constructor(public snackBar: MatSnackBar, private zone: NgZone) {
    }

    open(message, action = 'CERRAR', duration = 4000) {
        this.zone.run(() => {
            this.snackBar.open(message, action, { duration });
        })
    }

    error(message){
        this.zone.run(() => {
            this.snackBar.open(message, 'CERRAR', {
                 duration : 4000,
                 panelClass:['snack-bar-error']
                });
        }) 
    }

    success(message){
        this.zone.run(() => {
            this.snackBar.open(message, 'CERRAR', {
                 duration : 4000,
                 panelClass:['snack-bar-success']
                });
        }) 
    }

    warning(message){
        this.zone.run(() => {
            this.snackBar.open(message, 'CERRAR', {
                 duration : 4000,
                 panelClass:['snack-bar-warning']
                });
        }) 
    }

    info(message){
        this.zone.run(() => {
            this.snackBar.open(message, 'CERRAR', {
                 duration : 300000,
                 panelClass:['snack-bar-info']
                });
        }) 
    }


    verification(res) {      
        switch (res.status) {
            case 201:
                this.success("Datos guardados correctamente.");               
                break;
            case 204:
                this.success("Eliminado correctamente.");  
                break;
            case 200:
                this.success("Sus datos han sido guardados satisfactoriamente.");  
                break;
            case 422:
                this.error( res.json().errors[0].detail + '.');                
                break;
            case 401:
                let msj = res.error.error_description;
                this.error("Su sesión a expirado. Por favor ingrese nuevamente");  
                AppUtils.logout();
                break;
            case 0:
            case 504:
                this.error("No existe conexión con el servidor.");               
                break;
            case 500:
                this.error("Error en el servidor.");
                break;
            case 400:
                this.error("Error en el envio de datos.");               
                break;
            case 404:
                this.error("Dirección no encontrada..");  
                break;
        }
    }

    showMessage(type = 'success', mess) {
        switch (type) {
            case 'success':
              this.success(mess);  
                break;
            case 'error':
               this.error(mess);  
                break;
        }
    }

}