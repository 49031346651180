import {Component, Input, Output, ContentChildren, QueryList, AfterContentChecked, ViewEncapsulation} from '@angular/core';
import { QxDetailsFieldComponent } from './qx-details-field.component';

@Component({
    selector: 'qx-details',
    templateUrl: './qx-details.component.html',
    encapsulation: ViewEncapsulation.None,
    host: {
        'class': 'row col-sm-12 padding-top-20'
    }
})
export class QxDetailsComponent implements AfterContentChecked {
    @Input() data: any;
    @ContentChildren(QxDetailsFieldComponent) fields: QueryList<QxDetailsFieldComponent>;

    ngAfterContentChecked() {
        this.fields.forEach(f => f.model = this.data);
    }

}
