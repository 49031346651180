import {Component, Input, Output, AfterContentChecked, OnInit} from '@angular/core';
import { AppUtils } from '@quiox/global/helpers/app.utils';

@Component({
    selector: 'qx-details-field',
    templateUrl: './qx-details-field.component.html',
    styleUrls: ['./qx-details-field.component.scss'],
    host: {
        '[class]': 'colspan'
    }
})
export class QxDetailsFieldComponent {
    @Input() span: number;
    @Input() label: string;
    @Input() value: any;
    @Input() name: string;  
    @Input() stylered:boolean=false;  
    model: any;
    
    getValue() {
        const data = this.model;
        if (AppUtils.isNullOrUndefined(this.name)) {
            return this.value;
        }

        if (this.name) {
            let value = data;
            this.name.split(".").forEach(name => value = value ? value[name] : value);
            if (AppUtils.isObject(value)) {
                value = value.nombre || value.nombreCompleto;
            }

            if (AppUtils.isBoolean(value)) {
                value = value ? 'Sí' : 'No';
            }

            if (value == '') {
                value = null;
            }

            return !AppUtils.isNullOrUndefined(value) ? value : '-';
        }        
    }    

    get colspan() {
        return 'col-sm-' + this.span;
    }
}
