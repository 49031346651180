import { AbstractControl, ValidationErrors, Validators } from '@angular/forms';
import { StringMap } from '@angular/compiler/src/compiler_facade_interface';
import { AppUtils } from './app.utils';

export class AppValidators {
    static isAnObject(control: AbstractControl): ValidationErrors | null {
        if (!control.value) {
            return null;
        }
        
        if (!AppUtils.isObject(control.value)) {
            return {notOject: true};
        }
        
        return null;
    }

     static isOnlyNumbersAnLeters(control: AbstractControl): ValidationErrors | null {     
        if (!control.value) {
            return null;
        }
        
        if (!AppUtils.isNullOrUndefined(control.value) && control.value!="") {
         
            for (let index = 0; index < (control.value as String).length; index++) {
                let element:String =  (control.value as String)[index];
                if(!element.match(/^[0-9a-zA-Z]/) ){                 
                    return {notOject: true};
                }                
            }
        }
        
        return null;
    }

    static isOnlyNumber(control: AbstractControl): ValidationErrors | null {
        if (!control.value) {
            return null;
        }
        
            if (Number.isFinite(control.value)){ 
                if( Number.parseFloat(control.value)  < 0 ){                
                    return { notNumber: true };
                }          
                
            }else{
                return { notNumber: true };
            }
      
        return null;
    }

    static isInteger(control: AbstractControl): ValidationErrors | null {
        if (!control.value) {
            return null;
        }
            if (!Number.isInteger(control.value)){  
                    return { notInteger: true };                       
            }else{
                if( Number.parseFloat(control.value)  < 0 ){                
                    return { notNumber: true };
                }    
            }
      
        return null;
    }

    static isNumberNegative(control: AbstractControl): ValidationErrors | null {
        if (!control.value) {
            return null;
        }
        
            if (!Number.isFinite(control.value)){                         
                    return { notNumber: true };                     
            }
      
        return null;
    }
}
