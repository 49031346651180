<div mat-dialog-title>
    Cambiar Contraseña
    <mat-icon style="cursor:pointer !important; float: right !important;padding-left: 239px;padding-right: 26px; " (click)="dialogRef.close()">close</mat-icon>
</div>
<mat-dialog-content>
    <form class="padding-top-15" [formGroup]="form">       

            <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap.gt-sm="24px">
                <mat-form-field fxFlex class="input-container ">
                    <mat-label>Contraseña Actual</mat-label>
                    <input matInput type="password" required formControlName="actualClave">
                    <mat-error *ngIf="!form.controls.actualClave.valid && form.controls.actualClave.touched">
                        {{'Campo requerido'}}
                    </mat-error>
                </mat-form-field>
            </div>
            <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap.gt-sm="24px">
                <mat-form-field fxFlex class="input-container">
                        <mat-label>Contraseña Nueva</mat-label>
                    <input matInput type="password" required  formControlName="nuevaClave">
                    <mat-error *ngIf="!form.controls.nuevaClave.valid && form.controls.nuevaClave.touched">
                        {{'Campo requerido'}}
                    </mat-error>
                </mat-form-field>
            </div>
            <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap.gt-sm="24px">
                <mat-form-field fxFlex class="input-container">
                        <mat-label>Confirmar Contraseña</mat-label>
                    <input matInput type="password" required formControlName="nuevaClave2">
                    <mat-error *ngIf="!form.controls.nuevaClave2.valid && form.controls.nuevaClave2.touched">
                        {{'Campo requerido'}}
                    </mat-error>
                </mat-form-field>
            </div>       
    </form>
</mat-dialog-content>

<mat-dialog-actions fxLayout="row" fxLayoutAlign="end center" style="padding-bottom: 20px;">
    <button type="button" mat-button (click)="dialogRef.close()">
        CANCELAR
    </button>
    <button [disabled]="!form.valid" type="button"  mat-raised-button color="primary"
        (click)="save(form.value)">
        ACEPTAR
    </button>
</mat-dialog-actions>