import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QxFilterDateComponent } from './qx-filter-date/qx-filter-date.component';
import { QxFilterInputComponent } from './qx-filter-input/qx-filter-input.component';
import { QxFilterListComponent } from './qx-filter-list/qx-filter-list.component';
import { QxFilterComponent } from './qx-filter.component';
import { QxFilterListService } from './qx-filter-list/qx-filter-list.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../../../../@fury/shared/material-components.module';
import { MatChipsModule } from '@angular/material/chips';

@NgModule({
  declarations: [QxFilterComponent, QxFilterDateComponent, QxFilterInputComponent, QxFilterListComponent],
  imports: [
    CommonModule,
    MaterialModule,    
    FormsModule,
    ReactiveFormsModule,
    MatChipsModule
  ],
  exports: [QxFilterComponent],
  providers: [QxFilterListService],
  entryComponents: [QxFilterDateComponent, QxFilterInputComponent, QxFilterListComponent]
})
export class QxFilterModule { }
