import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; // Needed for Touch functionality of Material Components
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutModule } from './layout/layout.module';
import { environment } from '../environments/environment';
import { PendingInterceptorModule } from '../@fury/shared/loading-indicator/pending-interceptor.module';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions } from '@angular/material/form-field';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarConfig } from '@angular/material/snack-bar';
import { GlobalModule, MY_FORMATS } from './global/global.module';
import { OAuthResourceServerErrorHandler, OAuthNoopResourceServerErrorHandler } from './global/interceptors/resource-server-error-handler';
import { ProgressBarService } from './global/services/progress-bar.service';
import { Router } from '@angular/router';
import { FurySharedModule } from '../@fury/fury-shared.module';
import { HighlightModule } from '../@fury/shared/highlightjs/highlight.module';
import { FuryCardModule } from '../@fury/shared/card/card.module';
import { BreadcrumbsModule } from '../@fury/shared/breadcrumbs/breadcrumbs.module';
import { MaterialModule } from '../@fury/shared/material-components.module';
import { PageModule } from '../@fury/shared/page/page.module';
import { ListModule } from '../@fury/shared/list/list.module';
import { QuillModule } from 'ngx-quill';
import { SnackBarService } from './global/helpers/snack-bar.service';
import { AppConfigLoaderService } from './global/services/app-config-loader.service';
import { AppUtils } from './global/helpers/app.utils';
import { MAT_DATE_LOCALE, MAT_DATE_FORMATS, DateAdapter } from '@angular/material/core';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DefaultOAuthInterceptor } from './global/interceptors/default-oauth.interceptor';

@NgModule({
  imports: [
    // Angular Core Module // Don't remove!
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,

    // Fury Core Modules
    AppRoutingModule,

    // Layout Module (Sidenav, Toolbar, Quickpanel, Content)
    LayoutModule,

   
    // Displays Loading Bar when a Route Request or HTTP Request is pending
    PendingInterceptorModule,

    // Register a Service Worker (optional)
    // ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })

    GlobalModule,
    FurySharedModule,
    // Core
HighlightModule,
FuryCardModule,
BreadcrumbsModule,
MaterialModule,
PageModule,
ListModule,
QuillModule.forRoot(),
  ],
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  providers: [       
    SnackBarService,
    {provide: MAT_DATE_LOCALE, useValue: 'es'},
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},    
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'fill'
      } as MatFormFieldDefaultOptions
    },   
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: {
        duration: 5000,
        horizontalPosition: 'end',
        verticalPosition: 'bottom'
      } as MatSnackBarConfig
    },
    , 
    {
      provide: OAuthResourceServerErrorHandler,
      useClass: OAuthNoopResourceServerErrorHandler,
      deps: [ProgressBarService, Router]
  },
  {
      provide: HTTP_INTERCEPTORS,
      useClass: DefaultOAuthInterceptor,
      multi: true
  },
    AppConfigLoaderService,
    {
      provide:APP_INITIALIZER,
      deps:[AppConfigLoaderService],
      useFactory:apiConfigProvider,
      multi:true,
    }
  ]
})
export class AppModule {
}

 export function apiConfigProvider(appConfigLoaderSrvice: AppConfigLoaderService):any{
  return () => {
    appConfigLoaderSrvice.loadConfiguration(); 
  } 
}
