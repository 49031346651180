import { ModuleFuryConfig } from '@quiox/global/config/config';

const encuesta_cas = 'http://localhost:8010/auth/login';

export const administracionmenu : ModuleFuryConfig = {
    name: 'Administración',
    menu: [
        {
            name: 'Administración',
            position: 10,
            type: 'subheading',
            customClass: 'first-subheading',
            roles: [
                'ROLE_MASTER',
                'ROLE_CLIENT_OWNER'                
            ]
          },
          {
            name: 'Encuestas',
            routeOrFunction: '/admin/encuestas',
            icon: 'format_list_bulleted',
            position: 11,
            roles: [
                'ROLE_CLIENT_OWNER'
            ]
          },
          {
            name: 'Grupos',
            routeOrFunction: '/admin/grupo',
            icon: 'group',
            position: 12,
            roles: [
                'ROLE_CLIENT_OWNER'
            ]
          },          
          {
            name: 'Clientes',
            routeOrFunction: '/admin/clientes',
            icon: 'group',
            position: 13,
            roles: [
                'ROLE_MASTER'
            ]
          },
          {
            name: 'Usuarios',
            routeOrFunction: '/admin/usuarios',
            icon: 'group',
            position: 14,
            roles: [
                'ROLE_MASTER',
                'ROLE_CLIENT_OWNER'
            ]
          },          
          {
            name: 'Asociar Encuestas',
            routeOrFunction: '/admin/asociar/encuesta',
            icon: 'group',
            position: 40,
            roles: [
                'ROLE_GESTIONAR_USUARIO'
            ]
          },
          {
            name: 'Migrar Cognito',
            routeOrFunction: '/admin/migrar',
            icon: 'group',
            position: 41,
            roles: [ 
               'ROLE_MASTER',
               'ROLE_CLIENT_OWNER'
        ]
        },
          /*{
            name: 'Plantillas',
            routeOrFunction: '/admin/plantillas',
            icon: 'insert_drive_file',
            position: 45,
            roles: [
                'ROLE_CLIENT_OWNER'
            ]
          },*/
          {
            name: 'Importar Usuario',
            routeOrFunction: '/admin/importar/usuario',
            icon: 'group',
            position: 50,
            roles: [
                'ROLE_CLIENT_OWNER2'
            ]
          },   
       
    ]
        
};
