import {Injectable} from '@angular/core';
import {config} from '../config/config';
import {HttpClient} from '@angular/common/http';


@Injectable()
export class RolesService {
    constructor(private http: HttpClient) {
    }

    get() {
        return new Promise<any>((resolve, reject) => {
            this.http.get('api/user/roles').subscribe(res => {
                resolve(res);
            }, error => {
                reject(error);
            });
        });
    }

}
