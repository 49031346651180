import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';


@Injectable({
    providedIn: 'root'
})
export class ResourceRemoteService {   

    constructor(private httpClient: HttpClient) {
    }

    getUrl(name: string): Observable<string> {
        return this.httpClient.get('api/resource/' + name).pipe(map((r: any) => r.data));
    }

    getContent(url: string): Observable<any> {
        return this.httpClient.get(url, {responseType: 'blob'});
    }

}
