import {Routes, RouterModule, PreloadAllModules} from '@angular/router';
import {NgModule} from '@angular/core';
import {QxPageComponent} from '../core/qx-page.component';
import {PerfilComponent} from './perfil.component';

const routes: Routes = [
    {
        path: 'webapp',
        component: QxPageComponent,        
        children: [
            {
                path: 'perfil',
                component: PerfilComponent
            },
        ]
    }
];

@NgModule({
    imports: [
        RouterModule.forChild(routes)
    ]
})
export class PerfilRoutingModule {
}