import {AfterViewInit, Component, HostBinding, HostListener, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {AppUtils} from '../../helpers/app.utils';
import {User} from '../../models/user';
import {NavigationService} from '../../services/navigation.service';
import {ProgressBarService} from '../../services/progress-bar.service';
import {DomSanitizer} from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';

@Component({
    selector: 'app-qx-page',
    templateUrl: './qx-page.component.html',
    styleUrls: ['./qx-page.component.scss']
})
export class QxPageComponent implements OnInit, AfterViewInit{
    currentUser: User;
    public pageTitle: string = '';
    @HostBinding('class.mobile') mobile;
    public isFinishLoad: boolean = false;

    mode = 'side';
    progressBarMode: string = 'query';
    @ViewChild('start', {static: false}) start: any;
    constructor(private router: Router,
                private progressBarService: ProgressBarService,
                public navigation: NavigationService,
                iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
        this.currentUser = AppUtils.currentUser();
        this.navigation.config();
        this.mobile = this.navigation.mediumScreenAndDown;
        iconRegistry
            .addSvgIcon('close',
                sanitizer.bypassSecurityTrustResourceUrl('/assets/img/ic_close_24px.svg'))
            .addSvgIcon('plus',
                sanitizer.bypassSecurityTrustResourceUrl('/assets/img/ic_add.svg'))
            .addSvgIcon('menu',
                sanitizer.bypassSecurityTrustResourceUrl('/assets/img/ic_menu.svg'))
            .addSvgIcon('delete',
                sanitizer.bypassSecurityTrustResourceUrl('/assets/img/ic_delete_24px.svg'))
            .addSvgIcon('edit',
                sanitizer.bypassSecurityTrustResourceUrl('/assets/img/ic_edit_24px.svg'))
            .addSvgIcon('check',
                sanitizer.bypassSecurityTrustResourceUrl('/assets/img/ic_check_24px.svg'))
            .addSvgIcon('more-vert',
                sanitizer.bypassSecurityTrustResourceUrl('/assets/img/ic_more_vert_24px.svg'))
                .addSvgIcon('more-horiz',
                sanitizer.bypassSecurityTrustResourceUrl('/assets/img/ic_more_horiz_24px.svg'))
            .addSvgIcon('swap',
                sanitizer.bypassSecurityTrustResourceUrl('/assets/img/ic_swap_horiz_24px.svg'))
            .addSvgIcon('sync',
                sanitizer.bypassSecurityTrustResourceUrl('/assets/img/ic_sync_24px.svg'))            
            .addSvgIcon('right',
                sanitizer.bypassSecurityTrustResourceUrl('/assets/img/ic_arrow_forward_24px.svg'))
            .addSvgIcon('change',
                sanitizer.bypassSecurityTrustResourceUrl('/assets/img/ic_swap_vertical_circle_24px.svg'))
            .addSvgIcon('visibility',
                sanitizer.bypassSecurityTrustResourceUrl('/assets/img/ic_visibility_24px.svg'))
            .addSvgIcon('link',
                sanitizer.bypassSecurityTrustResourceUrl('/assets/img/ic_insert_link_24px.svg'))
            .addSvgIcon('person_outline',
                sanitizer.bypassSecurityTrustResourceUrl('/assets/img/ic_person_outline_24px.svg'))
            .addSvgIcon('person_add',
                sanitizer.bypassSecurityTrustResourceUrl('/assets/img/ic_person_add_24px.svg'))
            .addSvgIcon('backspace',
                sanitizer.bypassSecurityTrustResourceUrl('/assets/img/ic_backspace_24px.svg'))
            .addSvgIcon('expand',
                sanitizer.bypassSecurityTrustResourceUrl('/assets/img/ic_expand_more_24px.svg'))
            .registerFontClassAlias('fontawesome', 'fa');
    }

    ngAfterViewInit(): void {
        this.isFinishLoad = true;
        setTimeout(() => {
            this.navigation.showSidenav.subscribe(showSidenav => {
                this.start.toggle(undefined, 'mouse');
            });
        }, 500);
        /*this.progressBarService.updateProgressBar$.subscribe((mode: string) => {
            setTimeout(()=>{
                this.progressBarMode = mode;
            });
        });*/
    }

    ngOnInit() {
        this.navigation.setPageTitle('My App');
        this.navigation.pageTitle.subscribe(pageTitle => {
            this.pageTitle = pageTitle;
        });
    }


    @HostListener('window:resize', ['$event'])
    public resize($event) {
        // Need this to trigger change detection for screen size changes!
        this.navigation.updateViewport();
    }
}
